<template>
  <app-layout>
	  <div class="page">
		  <!--视频-->
		  <div class="mask" v-if="videoState == true" @click="masksCloseFun"></div>
		  <div class="videomasks" v-if="videoState == true">
			  <video src="https://www.ningmeipc.com/goods/profile/upload/applet/aboutus.mp4" controls="controls" autoplay width="960" height="540">
			  </video>
		  </div>

		  <img src="./images/banner.webp" alt="" class="banner">
		  <div class="video-play-click"  @click="checkVideoFun">
				<div class="video-play-button">
					<div class="video-play"></div>
				</div>
		  </div>
		  <img src="./images/white.png" alt="" class="white" />
		  <img src="./images/introduce.png" alt="" class="introduce" />
		  <img src="./images/history_title.png" alt="" class="history-title" />
		  <div class="history">
			  <div
					  :class="unfold1 ? 'history-li1' : 'history-mini1'"
					  @mouseover="clickHistoryImg(1)"
			  ></div>
			  <div
					  :class="unfold2 ? 'history-li2' : 'history-mini2'"
					  @mouseover="clickHistoryImg(2)"
			  ></div>
			  <div
					  :class="unfold3 ? 'history-li3' : 'history-mini3'"
					  @mouseover="clickHistoryImg(3)"
			  ></div>
			  <div
					  :class="unfold4 ? 'history-li4' : 'history-mini4'"
					  @mouseover="clickHistoryImg(4)"
			  ></div>
		  </div>
		  <div style="position: relative">
			  <img src="./images/global_market.png" alt="" class="global-market" />
			  <div class="global-video">
				  <video
						  :fill="true"
						  autoplay="autoplay"
						  preload="auto"
						  controlslist="nodownload noplaybackrate "
						  disablePictureInPicture="true"
						  :muted="true"
						  :controls="false"
						  loop
						  src="../aboutUs/images/ningmeishipin.mp4"
						  style="width: 76.5rem !important;"
				  ></video>
			  </div>
		  </div>
		  <img src="./images/smart_factory.png" alt="" class="smart_factory" />
		  <img src="./images/honor.png" alt="" class="honor" />
		  <img
				  src="./images/businss_partner_title.png"
				  alt=""
				  class="business_partner"
		  />
		  <div class="business-partner-list">
			  <img
					  v-for="item in businessPartnerList"
					  :src="item"
					  alt=""
					  class="bussiness-partner-img"
			  />
		  </div>
		  <div style="height: 161px"></div>
	  </div>
  </app-layout>
	<home-backup/>
</template>

<script setup>
import { ref } from "vue";
import AppLayout from "@/components/AppLayout.vue";
import HomeBackup from "@/views/home/components/HomeBackup.vue";

let unfold1 = ref(true);
let unfold2 = ref(false);
let unfold3 = ref(false);
let unfold4 = ref(false);
let currentUnfoldIndex = ref(1);

let videoState = ref(false);
//点击按钮出现弹窗播放视频
const checkVideoFun = () => {
	videoState.value = true;

}
const masksCloseFun = () => {
	videoState.value = false;
}

const clickHistoryImg = (index) => {
  if (index != currentUnfoldIndex.value) {
    currentUnfoldIndex.value = index;
    switch (index) {
      case 1:
        unfold1.value = true;
        unfold2.value = false;
        unfold3.value = false;
        unfold4.value = false;
        break;
      case 2:
        unfold1.value = false;
        unfold2.value = true;
        unfold3.value = false;
        unfold4.value = false;
        break;
      case 3:
        unfold1.value = false;
        unfold2.value = false;
        unfold3.value = true;
        unfold4.value = false;
        break;
      case 4:
        unfold1.value = false;
        unfold2.value = false;
        unfold3.value = false;
        unfold4.value = true;
        break;
    }
    return;
  }
};

const businessPartnerList = [
  require("./images/businss_partner1.png"),
  require("./images/businss_partner2.png"),
  require("./images/businss_partner3.png"),
  require("./images/businss_partner4.png"),
  require("./images/businss_partner5.png"),
  require("./images/businss_partner6.png"),
  require("./images/businss_partner7.png"),
  require("./images/businss_partner8.png"),
  require("./images/businss_partner9.png"),
  require("./images/businss_partner10.png"),
  require("./images/businss_partner11.png"),
  require("./images/businss_partner12.png"),
];
</script>

<style scoped lang="less">
.page {
	width: 1920px;
	margin: 0 auto;
  background-color: #fff;
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 99;
		background-color: #000000;
		opacity: 0.6;
	}
	.videomasks {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
	}
	.banner{
		width: 1920px;
		position: absolute;
	}
	.video-play-click{
		cursor: pointer;
		position: absolute;
		width: 1920px;
		height: 700px;
		z-index: 10;
		.video-play-button{
			margin-top: 292px !important;
			margin: 0 auto;
			width: 110px;
			height: 110px;
			.video-play{
				border-radius: 50%;
				width: 110px;
				height: 110px;
				position: absolute;
				background: url('./images/play.png') no-repeat;
				background-size: 100%;
				
			}
		}
	}
  .white {
	  position: relative;
    margin-top: 23%;
    width: 1920px;
    height: 319px;
  }
  .introduce {
    margin: 0 auto;
    margin-top: 54px !important;
    display: block;
    width: 1286px;
    height: 522px;
  }
  .history-title {
    margin: 0 auto;
    margin-top: 148px !important;
    display: block;
    width: 227px;
    height: 85px;
  }
  .history {
    margin-top: 51px !important;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 1304px;
    height: 513px;
  }
  .history-li1 {
    width: 777px;
    height: 513px;
    background-image: url("./images/history1.png");
    background-size: 777px 513px;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .history-mini1 {
    width: 154px;
    height: 513px;
    background-image: url("./images/history1_mini.png");
    background-size: 154px 513px;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .history-li2 {
    width: 777px;
    height: 513px;
    background-image: url("./images/history2.png");
    background-size: 777px 513px;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .history-mini2 {
    width: 154px;
    height: 513px;
    background-image: url("./images/history2_mini.png");
    background-size: 154px 513px;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .history-li3 {
    width: 777px;
    height: 513px;
    background-image: url("./images/history3.png");
    background-size: 777px 513px;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .history-mini3 {
    width: 154px;
    height: 513px;
    background-image: url("./images/history3_mini.png");
    background-size: 154px 513px;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .history-li4 {
    width: 777px;
    height: 513px;
    background-image: url("./images/history4.png");
    background-size: 777px 513px;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .history-mini4 {
    width: 154px;
    height: 513px;
    background-image: url("./images/history4_mini.png");
    background-size: 154px 513px;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .global-market {
    margin: 0 auto;
    margin-top: 121px !important;
    display: block;
    width: 1304px;
    height: 772px;
  }
	.global-video{
		position: absolute;
		top: 16%;
		left: 18%;
	}
  .smart_factory {
    margin: 0 auto;
    margin-top: 119px !important;
    display: block;
    width: 1284px;
    height: 1116px;
  }
  .honor {
    margin: 0 auto;
    margin-top: 120px !important;
    display: block;
    width: 1292px;
    height: 830px;
  }
  .business_partner {
    margin: 0 auto;
    margin-top: 117px !important;
    display: block;
    width: 286px;
    height: 78px;
  }
  .business-partner-list {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
    margin-top: 49px !important;
    margin: 0 auto;
    width: 1280px;
    height: 407px;
	  .bussiness-partner-img{
		  width: 311px;
		  height: 124px
	  }
  }
}
</style>
